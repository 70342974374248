import LoginView from '@/views/LoginView.vue'
import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

import TestView from '@/views/TestView.vue'
import Test2View from '@/views/Test2View.vue'
import Test3View from '@/views/TestView3.vue'

import HomeView from '@/views/HomeView.vue'
import IndexView from '@/views/IndexView.vue'
import MenuView from '@/views/MenuView.vue'
import EstateView from '@/views/EstateView.vue'
import RoleView from '@/views/RoleView.vue'
import UserView from '@/views/UserView.vue'
import AreaView from '@/views/AreaView.vue'
import DTUView from '@/views/DTUView.vue'
import ProtocolView from '@/views/ProtocolView.vue'
import PriceView from '@/views/PriceView.vue'
import WatermeterView from '@/views/WatermeterView.vue'
import AmmeterView from '@/views/AmmeterView.vue'
import RoomView from '@/views/RoomView.vue'
import EstateConfigView from '@/views/EstateConfigView.vue'
import AutoCountConfigView from '@/views/AutoCountConfigView.vue'
import SystemInfoView from '@/views/SystemInfoView.vue'
import ReadAmmeterView from '@/views/ReadAmmeterView.vue'
import ReadWatermeterView from '@/views/ReadWatermeterView.vue'
import SetAmmeterView from '@/views/SetAmmeterView.vue'
import SetWholeAmmeterView from '@/views/SetWholeAmmeterView.vue'
import SetWatermeterView from '@/views/SetWatermeterView.vue'
import ChangeMeterView from '@/views/ChangeMeterView.vue'
import ReturnsView from '@/views/ReturnsView.vue'
import AmmeterRecordView from '@/views/AmmeterRecordView.vue'
import WatermeterRecordView from '@/views/WatermeterRecordView.vue'
import RoomUseView from '@/views/RoomUseView.vue'
import RoomWaterUseView from '@/views/RoomWaterUseView.vue'
import ChargeMeterView from '@/views/ChargeMeterView.vue'
import ChargeMeterMinusView from '@/views/ChargeMeterMinusView.vue'
import ChargeWaterMeterView from '@/views/ChargeWaterMeterView.vue'
import ChargeMeterRecordView from '@/views/ChargeMeterRecordView.vue'
import ChargeWaterMeterRecordView from '@/views/ChargeWaterMeterRecordView.vue'
import ChargeBalanceView from '@/views/ChargeBalanceView.vue'
import ChargeBalanceRecordView from '@/views/ChargeBalanceRecordView.vue'
import BillCountView from '@/views/BillCountView.vue'
import BillCountRecordView from '@/views/BillCountRecordView.vue'
import OweRoomView from '@/views/OweRoomView.vue'
import DubiousRoomView from '@/views/DubiousRoomView.vue'
import ChangeMeterRecordView from '@/views/ChangeMeterRecordView.vue'
import PaymentView from '@/views/PaymentView.vue'
import GiftMeterView from '@/views/GiftMeterView.vue'
import GiftWaterMeterView from '@/views/GiftWaterMeterView.vue'
import GiftMeterRecordView from '@/views/GiftMeterRecordView.vue'
import GiftWaterMeterRecordView from '@/views/GiftWaterMeterRecordView.vue'
import OnOffMeterView from '@/views/OnOffMeterView.vue'
import OnOffWaterMeterView from '@/views/OnOffWaterMeterView.vue'
import OnOffMeterRecordView from '@/views/OnOffMeterRecordView.vue'
import OnOffWaterMeterRecordView from '@/views/OnOffWaterMeterRecordView.vue'
import SMSTempletView from '@/views/SMSTempletView.vue'
import SMSRecordView from '@/views/SMSRecordView.vue'
import SetMeterRecordView from '@/views/SetMeterRecordView.vue'
import SyncSoldAmountView from '@/views/SyncSoldAmountView.vue'
import SyncWaterSoldAmountView from '@/views/SyncWaterSoldAmountView.vue'
import WaterMeterResetZeroView from '@/views/WaterMeterResetZeroView.vue'
import MeterResetZeroView from '@/views/MeterResetZeroView.vue'
import MalignantLoadSetView from '@/views/MalignantLoadSetView.vue'
import OverOffRecordView from '@/views/OverOffRecordView.vue'
import BillStatisticsView from '@/views/BillStatisticsView.vue'
import LargeScreenView from '@/views/LargeScreenView.vue'
import SystemConfigView from '@/views/SystemConfigView.vue'
import CancelRechargeView from '@/views/CancelRechargeView.vue'
import CancelRoomAccountView from '@/views/CancelRoomAccountView.vue'
import CancelPaymentView from '@/views/CancelPaymentView.vue'
import AmUseChartView from '@/views/AmUseChartView.vue'
import WaterUseChartView from '@/views/WaterUseChartView.vue'
import ChargeAreaView from '@/views/ChargeAreaView.vue'
import ThirdUserView from '@/views/ThirdUserView.vue'

const routes = [
	{
		path: '/',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/LargeScreen',
		name: 'LargeScreen',
		component: LargeScreenView
	},
	{
		path: '/Home',
		name: 'Home',
		component: HomeView,
		children: [
			{
				path: '/Index',
				name: 'Index',
				component: IndexView
			},
			{
				path: '/Menu',
				name: 'Menu',
				component: MenuView
			},
			{
				path: '/Estate',
				name: 'Estate',
				component: EstateView
			},
			{
				path: '/Role',
				name: 'Role',
				component: RoleView
			},
			{
				path: '/User',
				name: 'User',
				component: UserView
			},
			{
				path: '/Area',
				name: 'Area',
				component: AreaView
			},
			{
				path: '/DTU',
				name: 'DTU',
				component: DTUView
			},
			{
				path: '/Protocol',
				name: 'Protocol',
				component: ProtocolView
			},
			{
				path: '/Price',
				name: 'Price',
				component: PriceView
			},
			{
				path: '/Watermeter',
				name: 'Watermeter',
				component: WatermeterView
			},
			{
				path: '/Ammeter',
				name: 'Ammeter',
				component: AmmeterView
			},
			{
				path: '/Room',
				name: 'Room',
				component: RoomView
			},
			{
				path: '/EstateConfig',
				name: 'EstateConfig',
				component: EstateConfigView
			},
			{
				path: '/AutoCountConfig',
				name: 'AutoCountConfig',
				component: AutoCountConfigView
			},
			{
				path: '/SystemInfo',
				name: 'SystemInfo',
				component: SystemInfoView
			},
			{
				path: '/ReadAmmeter',
				name: 'ReadAmmeter',
				component: ReadAmmeterView
			},
			{
				path: '/ReadWatermeter',
				name: 'ReadWatermeter',
				component: ReadWatermeterView
			},
			{
				path: '/SetAmmeter',
				name: 'SetAmmeter',
				component: SetAmmeterView
			},
			{
				path: '/SetWholeAmmeter',
				name: 'SetWholeAmmeter',
				component: SetWholeAmmeterView
			},
			{
				path: '/SetWatermeter',
				name: 'SetWatermeter',
				component: SetWatermeterView
			},
			{
				path: '/ChangeMeter',
				name: 'ChangeMeter',
				component: ChangeMeterView
			},
			{
				path: '/AmmeterRecord',
				name: 'AmmeterRecord',
				component: AmmeterRecordView
			},
			{
				path: '/WatermeterRecord',
				name: 'WatermeterRecord',
				component: WatermeterRecordView
			},
			{
				path: '/RoomUse',
				name: 'RoomUse',
				component: RoomUseView
			},
			{
				path: '/RoomWaterUse',
				name: 'RoomWaterUse',
				component: RoomWaterUseView
			},
			{
				path: '/ChargeMeter',
				name: 'ChargeMeter',
				component: ChargeMeterView
			},
			{
				path: '/ChargeMeterMinus',
				name: 'ChargeMeterMinus',
				component: ChargeMeterMinusView
			},
			{
				path: '/ChargeWaterMeter',
				name: 'ChargeWaterMeter',
				component: ChargeWaterMeterView
			},
			{
				path: '/ChargeMeterRecord',
				name: 'ChargeMeterRecord',
				component: ChargeMeterRecordView
			},
			{
				path: '/ChargeWaterMeterRecord',
				name: 'ChargeWaterMeterRecord',
				component: ChargeWaterMeterRecordView
			},
			{
				path: '/ChargeBalance',
				name: 'ChargeBalance',
				component: ChargeBalanceView
			},
			{
				path: '/ChargeBalanceRecord',
				name: 'ChargeBalanceRecord',
				component: ChargeBalanceRecordView
			},
			{
				path: '/BillCount',
				name: 'BillCount',
				component: BillCountView
			},
			{
				path: '/BillCountRecord',
				name: 'BillCountRecord',
				component: BillCountRecordView
			},
			{
				path: '/OweRoom',
				name: 'OweRoom',
				component: OweRoomView
			},
			{
				path: '/DubiousRoom',
				name: 'DubiousRoom',
				component: DubiousRoomView
			},
			{
				path: '/ChangeMeterRecord',
				name: 'ChangeMeterRecord',
				component: ChangeMeterRecordView
			},
			{
				path: '/Payment',
				name: 'Payment',
				component: PaymentView
			},
			{
				path: '/GiftMeter',
				name: 'GiftMeter',
				component: GiftMeterView
			},
			{
				path: '/GiftWaterMeter',
				name: 'GiftWaterMeter',
				component: GiftWaterMeterView
			},
			{
				path: '/GiftMeterRecord',
				name: 'GiftMeterRecord',
				component: GiftMeterRecordView
			},
			{
				path: '/GiftWaterMeterRecord',
				name: 'GiftWaterMeterRecord',
				component: GiftWaterMeterRecordView
			},
			{
				path: '/OnOffMeter',
				name: 'OnOffMeter',
				component: OnOffMeterView
			},
			{
				path: '/OnOffWaterMeter',
				name: 'OnOffWaterMeter',
				component: OnOffWaterMeterView
			},
			{
				path: '/OnOffMeterRecord',
				name: 'OnOffMeterRecord',
				component: OnOffMeterRecordView
			},
			{
				path: '/OnOffWaterMeterRecord',
				name: 'OnOffWaterMeterRecord',
				component: OnOffWaterMeterRecordView
			},
			{
				path: '/SMSTemplet',
				name: 'SMSTemplet',
				component: SMSTempletView
			},
			{
				path: '/SMSRecord',
				name: 'SMSRecord',
				component: SMSRecordView
			},
			{
				path: '/SetMeterRecord',
				name: 'SetMeterRecord',
				component: SetMeterRecordView
			},
			{
				path: '/SyncSoldAmount',
				name: 'SyncSoldAmount',
				component: SyncSoldAmountView
			},
			{
				path: '/SyncWaterSoldAmount',
				name: 'SyncWaterSoldAmount',
				component: SyncWaterSoldAmountView
			},
			{
				path: '/MeterResetZero',
				name: 'MeterResetZero',
				component: MeterResetZeroView
			},
			{
				path: '/WaterMeterResetZero',
				name: 'WaterMeterResetZero',
				component: WaterMeterResetZeroView
			},
			{
				path: '/MalignantLoadSet',
				name: 'MalignantLoadSet',
				component: MalignantLoadSetView
			},
			{
				path: '/OverOffRecord',
				name: 'OverOffRecord',
				component: OverOffRecordView
			},
			{
				path: '/BillStatistics',
				name: 'BillStatistics',
				component: BillStatisticsView
			},
			{
				path: '/SystemConfig',
				name: 'SystemConfig',
				component: SystemConfigView
			},
			{
				path: '/CancelRecharge',
				name: 'CancelRecharge',
				component: CancelRechargeView
			},
			{
				path: '/CancelRoomAccount',
				name: 'CancelRoomAccount',
				component: CancelRoomAccountView
			},
			{
				path: '/CancelPayment',
				name: 'CancelPayment',
				component: CancelPaymentView
			},
			{
				path: '/AmUseChart',
				name: 'AmUseChart',
				component: AmUseChartView
			},
			{
				path: '/WaterUseChart',
				name: 'WaterUseChart',
				component: WaterUseChartView
			},
			{
				path: '/ChargeArea',
				name: 'ChargeArea',
				component: ChargeAreaView
			},
			{
				path: '/ThirdUser',
				name: 'ThirdUser',
				component: ThirdUserView
			}
		]
	}, {
		path: '/Test',
		name: 'Test',
		component: TestView
	}, {
		path: '/Test2',
		name: 'Test2',
		component: Test2View
	}, {
		path: '/Test3',
		name: 'Test3',
		component: Test3View
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
	//console.log(to);
	//console.log(from);
	let flag = false;
	if (to.path == '/'||to.path == '/Login'||to.path=='/Home'||to.path=='/Index'||to.path=='/LargeScreen'||to.path.indexOf('Test')>-1) {
		flag = true;
	} else {
		let usermenus = JSON.parse(localStorage.getItem("usermenus"))
		if (usermenus && usermenus.length > 0) {
			for (let i = 0; i < usermenus.length; i++) {
				let menus = usermenus[i].menuItems;
				if (menus && menus.length > 0) {
					for (let j = 0; j < menus.length; j++) {
						if (menus[j].path == to.path) {
							flag = true;
							break;
						}
					}
					if (flag) {
						break;
					}
				}
			}
		}
	}
	next(flag)
})

export default router
